import axios from 'axios';


const baseURL = 'http://delivery.nrg.in.ua';

const httpClient = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        accept: 'application/json',
    }, 
});

export default httpClient;