import axios from 'axios';
import React from 'react';
import { useEffect, useState } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ServiceAccountActivate() {

    const baseURL = 'http://delivery.nrg.in.ua';
    
    const httpClientActiveOrders = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            accept: 'application/json',
        }, 
    });

    const [message, setResponseMessages] = React.useState([]);

    const initialFormData = Object.freeze({
        account_name: '',
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
            httpClientActiveOrders.post('/api/v1/account/activate', {
                account_name: formData.account_name,
            })
            .then(function (response) {
                const message_200 = `Обліковий запис ${formData.account_name} активований `;
                setResponseMessages(message_200)
            })
            // Если запрос с ошибкой
            .catch(function (error) {
            if (error.response) {
                if (error.response.status === 400){
                    const message_400 = 'Облікового запису не існує в системі';
                    setResponseMessages(message_400);
                } 
                if(error.response.status === 403){
                    const message_403 = 'Немає доступу для активації облікового запису ';
                    setResponseMessages(message_403);
                }
            }
            });
    };


    const classes = useStyles();


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <CheckCircleIcon style={{ fontSize: 40 }}></CheckCircleIcon>
                <Typography component="h1" variant="h5">
                    Активація облікового запису клієнта
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="account_name"
                        label="Аккаунт клієнта"
                        name="account_name"
                        autoComplete="account_name"
                        autoFocus
                        onChange={handleChange}
                    />
                    <Typography variant="h6">
                    <font color="green">{message}</font>
                    </Typography>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Активація
                    </Button>
                </form>
            </div>
        </Container>
    );
}