import axios from 'axios';
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


export default function Registration() {

    const baseURL = 'http://delivery.nrg.in.ua';
    
    const httpClientRegistration = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            accept: 'application/json',
        }, 
    });

    const [message, setResponseMessages] = React.useState([]);

    const initialFormData = Object.freeze({
        user_name: '',
        password: '',
        roles: '',
    });

    const [formData, updateFormData] = useState(initialFormData);
    const [checkboxRoles, updateCheckBoxRoles] = useState([])


    const handleChange = (e, item) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        let checkBoxData = checkboxRoles;
            if (e.target.name === 'roles'){
                checkBoxData.push(e.target.value);
                updateCheckBoxRoles(checkBoxData);
            }
    };
    console.log(checkboxRoles)


    const handleSubmit = (e) => {
        e.preventDefault();

        httpClientRegistration
            .post('/api/v1/registration', {
                user_name: formData.user_name,
                password: formData.password,
                roles: checkboxRoles,
            })
            .then(function (response) {
                const message_201 = `Обліковий запис ${formData.user_name} зареєстрований `;
                setResponseMessages(message_201)
            })
            // Если запрос с ошибкой
            .catch(function (error) {
            if (error.response) {
                if (error.response.status === 400){
                    const message_400 = "Введіть коректні дані";
                    setResponseMessages(message_400);
                } 
                if(error.response.status === 403){
                    const message_403 = 'Немає доступу для реєстрації облікового запису';
                    setResponseMessages(message_403);
                }
            }
            });
    };




    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}></Avatar>
                <Typography component="h1" variant="h5">
                    Реєстрація адміністраторів і операторів
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="user_name"
                        id="user_name"
                        label="Ім'я користувача"
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        autoComplete="password"
                        onChange={handleChange}
                    />
    
                    <Box sx={{ display: 'flex' }}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Роль</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox onChange={handleChange} name="roles" value="is_admin"/>
                            }
                            label="Адміністратор"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox onChange={handleChange} name="roles" value="is_operator"/>
                            }
                            label="Оператор"
                          />
                        </FormGroup>
                      </FormControl>
                    </Box>

                    <Typography variant="h6">
                    <font color="green">{message}</font>
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Реєстрація
                    </Button>
                </form>
            </div>
        </Container>
    );
}