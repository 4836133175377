import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import MyAppBar from './components/Header/header';
import Login from './components/Login/login';
import Registration from './components/Login/registration';
import RegistrationAcc from './components/ServiceAccount/registration_service';
import ServiceAccount from './components/ServiceAccount/service_account_activate';
import ServiceAccountDeactivation from './components/ServiceAccount/service_account_deactivate';
import RegistrationCourier from './components/Login/registration_courier';

const routing = (
    <Router>
        <React.StrictMode>
            <MyAppBar />
            <Switch>
                <Redirect exact from="/" to="/login" />
                <Route exact path="/home" component={App} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/registration" component={Registration} />
                <Route exact path="/registrationAcc" component={RegistrationAcc} />
                <Route exact path="/service-account-activate" component={ServiceAccount} />
                <Route exact path="/service-account-deactivate" component={ServiceAccountDeactivation} />
                <Route exact path="/registration-courier" component={RegistrationCourier} />
            </Switch>
        </React.StrictMode>
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
reportWebVitals();


