
import React, { useEffect, useState, } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';


let operatorToken = localStorage.getItem('token')
const operatorWebsocket = new WebSocket(`ws://delivery.nrg.in.ua:5001/ws/operator?token=${operatorToken}`);


const Alert = React.forwardRef(function Alert(props, ref) {
  return (<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



export default function Orders() {

const [orders, setOrders] = React.useState([]);
const [activeOrder, setActiveOrder] = React.useState([]);
const [open, setOpen] = React.useState(false);
const [disable, setDisable] = React.useState(false);
const [status, setStatus] = React.useState([])
const [openModal, setOpenModal] = React.useState(false);
const [message, setResponseMessages] = React.useState([]);
const [formData, updateFormData] = useState(initialFormData);
const [idOrder, setOrderId] = React.useState([]);

    const baseURL = 'http://delivery.nrg.in.ua';
    
    const httpClientSendToTelegram = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            accept: 'application/json',
        }, 
    });

  const handleOpen = (orderId) => {
    for(let order of orders) {
      if(order._id === orderId) {
      setOrderId(order._id)
      setOpenModal(true);
  }
}
  };
  const handleClosed = () => {
    setOpenModal(false);
  };

    const initialFormData = Object.freeze({
        reason: '',
    });


    const handleChange = (e, item) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
      }

    const handleSubmitCloseOrder = () => {
        httpClientSendToTelegram
            .post('/api/v1/close_order', {
                order_id : idOrder,
                reason: formData.reason,
            })
            .then(function (response) {
              window.location.reload();
            })
            .catch(function (error) {
            if (error.response) {
                if (error.response.status === 400){
                    const message_400 = "Введіть причину для закриття замовлення";
                    setResponseMessages(message_400);
                } 
            }
            });

    };


const handleClose = (event, reasons) => {
  if (reasons === 'clickaway') {
      return;
  }
     setOpen(false);
  };

// function changeStatus() {
//   for(let order of orders) {
//     if (order.status === "NEW"){
//       const newStatus = "Новий"
//       setStatus(newStatus)
//     }
//   }
// }

operatorWebsocket.onmessage = function(event) {
    let order = JSON.parse(event.data);
    if(Array.isArray(order)) {
      setOrders(oldOrders => [...oldOrders, ...order]);
   }
   if(!Array.isArray(order)) {
    let ordersCopy = [...orders];
    orders.forEach((existOrder, index) => {
      if (existOrder._id === order._id) {
        ordersCopy[index] = order
        setOrders(ordersCopy)
      }
    });
   }
};

useEffect(() => {
  changeColor();  
  // changeStatus();
  // hideButton();
 }, [orders]);
 

function changeColor() {
  for(let index=0; index<orders.length; index++) {
  setTimeout(() => {
  document.getElementById("cardColor").style.background = "#FF4500";}, 1000);
  setTimeout(() => {
  document.getElementById("cardColor").style.background = "white";}, 2000);
  }
}

// function hideButton() {
//   for(let order of orders) {
//     if (order.status === "APPROVED"){
//       setDisable(true)
//     }
//     if (order.status === "ACCEPTED"){
//       setDisable(false)
//     }
//   }
// }

// function hideButton() {
//   for(let order of orders) {
//     if (order.status === "APPROVED"){
//       setDisable(true)
//     }
//   }
// }

const handleSubmit = (orderId) => {
  for(let order of orders) {
    if(order._id === orderId) {
      setActiveOrder(order.payload);
      break;
    }
  } 
}


const sendCourier = (orderId) => {
  for(let order of orders) {
    if(order._id === orderId) {
      var sendCourierWS = {
         _id : order._id,
         payload: order.payload,
         status: "APPROVED"
    };
    operatorWebsocket.send(JSON.stringify(sendCourierWS));
    setOpen(true);
    httpClientSendToTelegram.post('/api/v1/send_to_telegram_order', {})
    }
  }
}



const classes = useStyles();

if (!orders || orders.length === 0) return(                     
<div className={classes.root}>
        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item xs={3}>

                  <Card>
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom variant="h6">
                    Немає активних доставок
                    </Typography>
                  </CardContent>
                </Card>

          </Grid>
      </Grid>
   </div>);
return (
<div className={classes.root}>
        <Grid container direction="row" justifyContent="space-between" spacing={2}>
          <Grid item xs={3} key={orders._id}>

                  {orders.slice(0).reverse().map(order => (
                  <Card id="cardColor">
                  <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom >
                    </Typography>
                    <Typography variant="h6" component="li" >
                      Клієнт: {order.client} <br />
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    </Typography>
                    <Typography variant="h6" component="li">
                      Статус замовлення: {order.status} {status}
                      <br />
                    </Typography>
                    <Typography variant="h6" component="li">
                      Час надходження замовлення: <br />{order.order_time}
                      <br />
                    </Typography>
                    <Typography variant="h6" component="li">
                      Час відправки кур’єрам замовлення: <br />{order.time_send_order_to_courier}
                      <br />
                    </Typography>
                      <Typography variant="h6" component="li" >
                      Кур’єр: {order.courier} <br />
                    </Typography>
                      <Typography variant="h6" component="li" >
                      Телефон кур’єра: {order.phone_number} <br />
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleSubmit(order._id)} >Інформація по доставці</Button>
                  </CardActions>
                  <CardActions>
                    <Button size="small" onClick={() => sendCourier(order._id)} disabled = {disable} >Відправити кур’єру інформацію по доставці</Button>
                  </CardActions>

                  <div>
                  <Button onClick={() => handleOpen(order._id)}>Відмінтии замовлення</Button>
                  <Modal
                    open={openModal}
                    onClose={handleClosed}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                  >
                    <Box sx={{ ...style, width: 500 }}>
                      <h2 id="parent-modal-title">Напишіть причину відміни замовлення:</h2>
                      <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 2, width: '50ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField id="reason" name="reason" onChange={handleChange} label="Напишіть тут" variant="standard" />
                    </Box>
                    <Typography variant="h6">
                    <font color="green">{message}</font>
                    </Typography>
                        <center><Button variant="contained" onClick={handleSubmitCloseOrder}>Відправити</Button></center>
                    </Box>
                  </Modal>
                  </div>

                </Card>
                  ))
                  }

                  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Сповіщення кур’єру відправлено!
                  </Alert>
                  </Snackbar>

          </Grid>
          <Grid item xs={9} key={orders._id}>
          {activeOrder.map(order => (
            <Paper className={classes.paper} >
            <Typography className={classes.title} color="textSecondary" align='left'>

            Замовлення: <b>{order.order} </b> <br />
            Адрес товару: <b>{order.delivery_from}</b> <br />
            Номер телефону продавця: <b>{order.seller_phone}</b> <br />
            Прізвище Ім’я по Батькові:  <b>{order.full_name_customer}</b> <br />
            Номер телефону покупця: <b>{order.customer_phone}</b> <br />
            Адрес доствки товару: <b>{order.delivery_to} </b> <br />
            Кількість товару: <b>{order.quantity}</b> <br />
            Ціна замовлення: <b>{order.amount}</b> <br />
            Година на яку має бути доставка (не пізніше) : <b>{order.delivery_time}</b> <br />
            Коментар:  <b>{order.comment}</b> <br />
            </Typography>
            </Paper>
            ))
          }
          </Grid>
      </Grid>
   </div>


  );
}
