  
import React from 'react';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import httpClient from '../../axios';
import { useHistory } from 'react-router-dom';
import { AppBar, Button, Toolbar } from "@material-ui/core";
import { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    toolbarTitle: {
        flexGrow: 1,
    },
}));


export default function MyAppBar() {

    const [isLogged, setisLogged] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    useEffect(() => {
    checkLoginUsername();
    return () => {};

  }, [isLogged]);

  function checkLoginUsername() {

    if (localStorage.getItem("user_name")) {
      setisLogged(true);
    } else {
      setisLogged(false);
    }
  }

    const classes = useStyles();
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        history.push('/home');
        window.location.reload(); 
            };

    const handleLogin = (e) => {
        e.preventDefault();
        history.push('/login');
            };

    const handleLogout = (e) => {
        e.preventDefault();
        httpClient
            .post('/api/v1/logout')
            .then((res) => {
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_name');
                localStorage.removeItem('last_login_at');
                localStorage.removeItem('role');
                history.push('/login');
                setisLogged(false);
            });
    };


    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} className={classes.appBar} >
                <Toolbar className={classes.toolbar}>
                {!isLogged ? (
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle} >
                <Link component={NavLink} to="/" underline="none" color="textPrimary"  onClick={handleLogin}>
                Сервіс Доставки
                </Link>
                </Typography>
                    ) : (
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle} >
                        <Link component={NavLink} to="/home" underline="none" color="textPrimary"  onClick={handleSubmit}>
                            Сервіс Доставки
                        </Link>&nbsp;&nbsp;&nbsp;
                          <Button
                            id="demo-customized-button"
                            aria-controls="demo-customized-menu"
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Навігація сайту
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={handleClose} className={classes.link} component={NavLink} to="/registration">Реєстрація</MenuItem>
                            <MenuItem onClick={handleClose} className={classes.link} component={NavLink} to="/registration-courier">Реєстрація кур’єра</MenuItem>
                            <MenuItem onClick={handleClose} className={classes.link} component={NavLink} to="/registrationAcc">Реєстрація клієнтів</MenuItem>
                            <MenuItem onClick={handleClose} className={classes.link} component={NavLink} to="/service-account-activate">Активація облікового запису клієнта</MenuItem>
                            <MenuItem onClick={handleClose} className={classes.link} component={NavLink} to="/service-account-deactivate">Деактивація облікового запису клієнта</MenuItem>
                          </Menu>
                    </Typography>
                    )}
                    {!isLogged ? (
                    <Button color="primary" variant="text" className={classes.link} component={NavLink} to="/login" >
                        Вхід
                    </Button>
                    ) : (
                    <Button color="primary" variant="text" className={classes.link} component={NavLink} to="/logout" onClick={handleLogout}>
                        Вихід
                    </Button>
                    )}


                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

