import httpClient from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const history = useHistory();
    const initialFormData = Object.freeze({
        user_name: '',
        password: '',
    });

    const [formData, updateFormData] = useState(initialFormData);
    const [message, setResponseMessages] = React.useState([]);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        

        httpClient
            .post('/api/v1/login', {
                user_name: formData.user_name,
                password: formData.password,
            })
            .then((res) => {
                localStorage.setItem('token', res.data.token);
                var token = (res.data.token)
                var decoded = jwt_decode(token);
                localStorage.setItem('user_id', decoded.user_id);
                localStorage.setItem('user_name', decoded.user_name);
                localStorage.setItem('last_login_at', decoded.last_login_at);
                localStorage.setItem('role', decoded.role);
                history.push('/home');
                window.location.reload(); 
            })
            .catch(function (error) {
            if (error.response) {
                if (error.response.status === 400){
                    const message_400 = "Невірно введено ім'я або пароль";
                    setResponseMessages(message_400);
                } 
            }
            });
    };


    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}></Avatar>
                <Typography component="h1" variant="h5">
                    Вхід
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="user_name"
                        label="Ім'я користувача"
                        name="user_name"
                        autoComplete="user_name"
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                    />
                    <Typography variant="h6">
                    <font color="red">{message}</font>
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                       Увійти
                    </Button>
                </form>
            </div>
        </Container>
    );
}